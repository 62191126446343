<template>
  <div>
    <save
      ref="saveForm"
      @onSuccess="getItems"
    />
    <div class="d-flex justify-content-between">
      <b-tabs>
        <b-tab
          v-for="tab in settingTabs"
          :key="tab.id"
          :title="tab.name"
          @click="onChange(tab.id)"
        />
      </b-tabs>
      <b-overlay :show="loadingBtn">
        <b-button
          variant="outline-success"
          class="float-right"
          size="small"
          @click="tableToExcel"
        >
          <img
            src="/images/excel_1.svg"
            alt=""
          >
          Yuklab olish
        </b-button>
      </b-overlay>
    </div>
    <b-overlay :show="loading">
      <good-table-column-search
        v-if="items"
        :items="items.data"
        :columns="columns"
        :total="items.total"
        :page="page"
        :has-show="true"
        model="iCloud"
        :filter="filterModel"
        @getItems="getItems"
        @add="$refs.saveForm.visible = true"
        @onPageChange="(p) => (page = p)"
      >
        <template
          slot="table-column"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'imei'">
            <p v-if="props.row.contract_product && props.row.contract_product.imei_1"><b>IMEI 1:</b> {{
              props.row.contract_product.imei_1 }}</p>
            <p v-if="props.row.contract_product && props.row.contract_product.imei_2"><b>IMEI 2:</b> {{
              props.row.contract_product.imei_2 }}</p>
          </span>
          <span v-if="props.column.field === 'duplicate'">
            <b-button
              size="sm"
              variant="outline-primary"
              class="btn-tour-skip "
              @click="openDModal(props.row.id)"
            >Duplikat</b-button>
          </span>
        </template>
      </good-table-column-search>
      <good-table-column-search
        v-if="itemsX"
        v-show="false"
        :items="itemsX.data"
        class="tablee"
        :columns="columns"
        :total="itemsX.total"
        :page="page"
        :filter="filterModel"
      />
    </b-overlay>
    <b-modal
      v-model="openDuplicateModal"
      hide-footer
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      centered
      title="Duplikat qilish"
      @close="openDuplicateModal = false"
      @cancel="openDuplicateModal = false"
    >
      <validation-observer
        ref="form"
        #default="{ invalid }"
      >
        <b-form class="auth-login-form mt-2">
          <b-form-group
            :label="$t('Duplikat soni')"
            label-for="name"
          >
            <validation-provider
              #default="{ errors }"
              name="duplicateCount"
              rules="required"
            >
              <b-form-input
                id="duplicateCount"
                v-model="duplicateCount"
                name="duplicateCount"
                :state="errors.length > 0 ? false:null"
                placeholder="1"
                type="number"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>

      <b-overlay :show="loading">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="w-100"
          variant="primary"
          @click="saveDuplicate"
        >
          Saqlash
        </b-button>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { showToast } from '@/utils/toast'
import GoodTableColumnSearch from '@/views/table/vue-good-table/GoodTableColumnSearch.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Save from './save.vue'

export default {
  name: 'Index',
  components: {
    GoodTableColumnSearch,
    Save,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      page: 1,
      loading: false,
      items: [],
      itemsX: [],
      openDuplicateModal: false,
      duplicateCount: null,
      duplicateId: null,
      filterModel: {
        per_page: 50,
        is_attached: null,
        relations: 'contractProduct',
      },
      loadingBtn: false,
      settingTabs: [
        {
          id: null,
          name: 'Hammasi',
        },
        {
          id: 1,
          name: 'Biriktirilganlar',
        },
        {
          id: 0,
          name: 'Biriktirilmagan',
        },
      ],
      //
      uri: 'data:application/vnd.ms-excel;base64,',
      template: '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta charset="utf-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64(s) { return window.btoa(unescape(encodeURIComponent(s))) },
      format(s, c) { return s.replace(/{(\w+)}/g, (m, p) => c[p]) },
    }
  },
  computed: {
    columns() {
      return [
        {
          label: '#',
          field: 'row_number',
        },
        {
          label: 'Email',
          field: 'mail',
          showField: true,
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: 'Ismi',
          field: 'firstname',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: 'Familiyasi',
          field: 'lastname',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: 'Telefon',
          field: 'phone',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: 'IMEI',
          field: 'imei',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: 'Дата создания',
          field: 'created_at',
          isDate: true,
        },
        {
          label: 'Status',
          field: 'attachStatus',
        },
        {
          label: ' ',
          field: 'duplicate',
        },
      ]
    },
  },
  watch: {
    page(newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
    'filterModel.per_page': function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
  },
  mounted() {
    this.getItems()
  },
  methods: {
    ...mapActions({
      getItemsAction: 'resource/getIClouds',
      iCloudDuplicate: 'resource/iCloudDuplicate',
    }),
    imeiField(row) {
      if (row.contract_product && row.contract_product.imei_1) {
        return `${row.contract_product.imei_1}, ${row.contract_product.imei_1 ? row.contract_product.imei_1 : ''}`
      }
      return ''
    },
    onChange(id) {
      this.filterModel.is_attached = id
      this.getItems()
    },
    async getItems() {
      this.loading = true
      await this.getItemsAction({ ...this.filterModel, page: this.page }).then(
        res => {
          this.items = res.data
        },
      )
      this.loading = false
    },
    async validationForm() {
      let validated = false
      await this.$refs.form.validate().then(success => {
        validated = success
      })
      return validated
    },
    async saveDuplicate() {
      const valid = await this.validationForm()
      if (valid) {
        this.loading = true
        await this.iCloudDuplicate({ count: this.duplicateCount, id: this.duplicateId }).then(
          res => {
            this.getItems()
            this.openDuplicateModal = false
          },
        ).finally(() => {
          this.loading = false
        })
      }
    },
    openDModal(id) {
      this.openDuplicateModal = true
      this.duplicateId = id
    },
    async getItemsX() {
      this.loadingBtn = true
      await this.getItemsAction({
        per_page: 99999,
        is_attached: null,
        page: this.page,
      }).then(
        res => {
          this.itemsX = res.data
        },
      )
      this.loadingBtn = false
    },
    nameByLocal(nameuz, nameru) {
      if (this.$i18n.locale == 'uz') {
        return nameuz
      }
      return nameru
    },
    destroy(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t('Вы действительно хотите удалить?'), {
          title: this.$t('Подтвердите'),
          size: 'sm',
          variant: 'warning',
          okVariant: 'primary',
          okTitle: this.$t('Да'),
          cancelTitle: this.$t('Нет'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.destroyAction(id)
              .then(res => {
                showToast('success', this.$t('Успешно удалено'))
                this.getItems()
              })
              .catch(() => {
                showToast('success', this.$t('Успешно удалено'))
              })
          }
        })
    },
    async tableToExcel(table) {
      this.loadingBtn = true
      await this.getItemsX()

      const tableeee = document.querySelector(
        'div.tablee div.vgt-responsive table',
      )
      const secondRow = tableeee.querySelector('thead tr:nth-child(2)')
      if (secondRow) {
        secondRow.remove()
      }
      setTimeout(() => {
        if (!table.nodeType) table = tableeee
        const ctx = { worksheet: `ICloud'lar ro'yhati (${this.todayDate})`, table: table.innerHTML }
        const link = document.createElement('a')
        link.download = `ICloud'lar ro'yhati (${this.todayDate}).xls`
        link.href = this.uri + this.base64(this.format(this.template, ctx))
        link.click()
        this.loadingBtn = false
      }, 100)
    },
  },
}
</script>

<style scoped></style>
